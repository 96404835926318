<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <div class="title">
          <span>05</span>
          <h3>幸福凯达</h3>
          <p>Welfare System</p>
        </div>
        <p>
          员工既是事业奋斗的重要伙伴，也是企业发展成果的共享人。凯达公司将营造风清气正、干事创业的企业文化氛围，为人才发展奠定基础，提高企业核心竞争力，帮助员工实现职业梦想和人生价值，同心共筑美好未来，致力打造将个人梦想和凯达梦想融为一体，将个人成就和企业发展融为一体的幸福凯达。
        </p>
        <p>
          <img src="../../assets/images/xfkd.jpg" alt="" />
        </p>
        <p><b>为价值付薪，加强薪酬体系保障</b></p>
        <p>
          在公平性、合理性、激励性原则的基础上，凯达公司为员工提供具有市场竞争力的薪酬待遇，高度重视员工的成长与发展诉求，最大限度激发全员积极性，使薪酬体系全面化、规范化、标准化，按照业绩高低，贡献大小完善薪酬分配机制，使人才的价值得到充分发挥和认可。
        </p>
        <p><b>提升福利保障，超越行业平均水平</b></p>
        <p>
          身心健康是幸福生活的前提，凯达公司重视员工身心健康，给予员工最温暖的人文关怀，为每位凯达人的幸福生活提供全面福利保障，让员工以最佳状态充实工作、愉快生活，不断提升归属感和幸福感，做到“事业引人、政策用人、培育成人，宽容待人、感情留人”。
        </p>
        <p><b>完善晋升机制，健全人才评价体系</b></p>
        <p>
          遵循纵向晋升与横向晋升并重的原则，通过科学高效、激励性强的荣誉制度和晋升机制，健全以“能力、业绩、创新、质量、实效、贡献”为导向的人才评价体系，探索人才智力成果、前沿技术等入股参与收入分配，使人才价值得到充分发挥和认可，保证晋升的合理性、公平性、公正性。
        </p>
        <p><b>搭建数字化平台，服务高素质人才</b></p>
        <p>
          打造国内领先、省内唯一的“凯达云”数字化管控平台，为高素质人才搭建数字化、高科技平台，通过平台、项目、人才一体化运作吸引高层次优秀人才，提升团队自豪感和荣誉感。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: { Crumb, Banner },
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner3.jpg"),
    };
  },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .content {
      color: #666;
      .title {
        background: url(../../assets/images/bg-title.png) no-repeat 0 0;
        width: 255px;
        height: 50px;
        color: #fff;
        margin-bottom: 20px;
        padding: 15px 20px;
        span {
          display: block;
          float: left;
          width: 50px;
          height: 50px;
          font-size: 24px;
          color: #eb2737;
          text-align: center;
          font-weight: bold;
          line-height: 50px;
          margin-right: 10px;
        }
        h3 {
          font-size: 20px;
          line-height: 30px;
          font-weight: normal;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      p {
        margin-bottom: 1em;
      }
      .red {
        color: #eb2737;
      }
      .align-right {
        text-align: right;
      }
    }
  }
}
</style>
